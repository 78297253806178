<template>
  <v-row class="text-center">
    <v-col class="col-sm-4 d-none d-sm-flex"></v-col>
    <v-spacer></v-spacer>
    <v-col align="center" class="col-12 col-sm-3">
      <!-- Loading -->
      <v-row v-if="loading" class="pt-5" justify="center">
        <v-icon>mdi mdi-loading mdi-spin</v-icon>
      </v-row>
      <v-card class="mt-5 pa-5">
        <!-- Error -->
        <v-row v-if="error" class="pb-5 red--text" justify="center">
          {{ error }}
        </v-row>
        <!-- Create Password -->
        <v-form v-if="$route.query && $route.query.token && !loading"
          @submit.prevent="submitChangePassword"
          ref="changePasswordForm"
          v-model="validChangePassword"
        >
          <h2>Create a New Password</h2>
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            :type="'password'"
            label="New Password"
            required
            @keydown.enter="submitChangePassword"
          ></v-text-field>
          <v-text-field
            v-model="password2"
            :rules="passwordRules"
            :type="'password'"
            label="Confirm Password"
            required
            @keydown.enter="submitChangePassword"
          ></v-text-field>
          <v-btn
            type="submit"
            class="mt-3 white--text"
            color="primaryButton"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-form>
        <!-- Reset Password -->
        <v-form v-else-if="!loading"
          @submit.prevent="submitResetPassword"
          ref="resetPasswordForm"
          v-model="validResetPassword"
        >
          <h2>Reset Your Password</h2>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            ref="emailField"
            label="Email Address"
            :disabled="isPasswordResetEmailSent"
            required
            @keydown.enter="submitResetPassword"
          ></v-text-field>
          <v-btn
            type="submit"
            class="mt-3 white--text"
            color="primaryButton"
            :loading="loading"
            :disabled="isPasswordResetEmailSent"
          >
            Submit
          </v-btn>
        </v-form>
        <!-- Verify Email -->
        <!-- <div v-if="$route.query.mode==='verifyEmail'">
        </div> -->
        <!-- Change Email -->
        <!-- <div v-else-if="$route.query.mode==='recoverEmail'">
        </div> -->
      </v-card>
    </v-col>
    <v-spacer></v-spacer>
    <v-col class="col-sm-4 d-none d-sm-flex"></v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'AuthManagement',

    data () {
      return {
        email: '',
        error: '',
        isPasswordResetEmailSent: false,
        emailRules: [
          v => !!v || 'E-mail is required.',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid.'
        ],
        loading: false,
        password: '',
        password2: '',
        validChangePassword: false,
        validResetPassword: false
      }
    },

    computed: {
      passwordRules() {
        const { password, password2 } = this
        return [
          v => !!v || 'Password is required.',
          v => v.length >= 8 || 'Passwords must contain at least 8 characters.',
          password == password2 || 'Passwords must match.'
        ]
      }
    },

    mounted () {
      this.error = ''
      if (this.$route.query.token) {
        const code = this.$route.query.token
        this.loading = true
        this.$store.dispatch('verifyPasswordResetCode', code)
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.error = error
          this.loading = false
        })
      } else if (this.$route.query.mode === 'verifyEmail') {
        this.$router.push('/login')
      }
    },

    methods: {
      submitChangePassword () {
        this.error = ''
        this.$refs.changePasswordForm.validate()
        if (this.validChangePassword && !this.loading) {
          this.loading = true
          const code = this.$route.query.token
          const { password } = this
          this.$store.dispatch('confirmPasswordReset', { code, password })
          .catch((error) => {
            this.error = error
            this.loading = false
          })
        }
      },

      submitResetPassword () {
        this.error = ''
        this.$refs.resetPasswordForm.validate()
        if (this.validResetPassword && !this.loading) {
          this.loading = true
          const { email } = this
          this.$store.dispatch('resetPassword', email)
          .then(() => {
            this.loading = false
            this.isPasswordResetEmailSent = true
            this.error = "We sent you an email to reset your password. Please follow the instructions in the email to continue."
          })
          .catch((error) => {
            this.error = error
            this.loading = false
          })
        }
      }
    }
  }
</script>
